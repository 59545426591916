<template>
  <v-container class="py-5 text-center">
    <div v-if="rules" v-html="rules" class="text-left"></div>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    rules: null,
  }),

  async created() {
    const { commit } = this.$store;
    commit("overlay/update", { active: true });
    try {
      this.rules = (
        await this.$api.get("loadPrivacy")
      ).details.data[0].html_privacy;
    } catch (e) {
      commit("snackbar/update", { active: true, text: "loadPrivacy error" });
    }
    commit("overlay/update", { active: false });
  },
};
</script>
